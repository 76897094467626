import React from "react";

import { Container } from "../components";

import { Styled404 } from "../components/css";
import { theme } from "../components/css/theme";

const PageNotFound = () => (

  <Container page="content">

    <div style={{ background: theme.primaryLight }}>

      <Styled404>

        <div>

          <h4>We can't find the page you are looking for.</h4>
          <h5>Click <a href="/">here</a> to return to our home page.</h5>

        </div>

      </Styled404>



    </div>
  </Container>
)

export default PageNotFound